<template>
  <div class="stateBarparent">
    <div class="stateBar"></div>
    <div class="towfour">24</div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'stateBar',
  data () {
    return {
      // 待机
      Standby: [],
      // 异常
      abnormal: [],
      // 关机
      shutdown: [],
      // 加工
      processing: [],
      // 日期
      xAxis: []
    }
  },
  mounted () {
    this.initdate()
    this.init()
  },
  methods: {
    init () {
      var chartDom = document.querySelector('.stateBar')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        legend: {
          data: ['加工', '待机', '异常', '关机'],
          top: 14,
          textStyle: {
            // 图例字体大小
            fontSize: 10,
            color: 'white'
          },
          // 图例大小
          itemHeight: 10
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '20%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.xAxis
        },
        yAxis: {
          type: 'value',
          axisTick: {
            show: true,
            lineStyle: {
              color: '#959595'
            }
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            name: '关机',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: this.shutdown,
            itemStyle: {
              normal: {
                color: '#8f8f8f'
              }
            }
          },

          {
            name: '异常',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: this.abnormal,
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(41, 40, 40,.5)'
            },
            barWidth: 15,
            itemStyle: {
              normal: {
                // 这里是重点
                color: '#fa4343'
              }
            }
          },

          {
            name: '待机',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: this.Standby,
            itemStyle: {
              normal: {
                color: '#f4a723'
              }
            }
          },

          {
            name: '加工',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: this.processing,
            itemStyle: {
              normal: {
                color: 'rgb(94, 188, 56,0.7)'
              }
            }
          }
        ]
      }
      myChart.clear()
      option && myChart.setOption(option)
    },
    initdate () {
      var date = new Date()
      var day = this.dateFormat('dd', date)
      if (day === '19' || day === '20' || day === '21' || day === '22') {
        this.xAxis = ['15', '16', '17', '18', '19', '20', '21']
        this.processing = [6, 11, 10, 10, 11, 12, 6]
        this.shutdown = [6, 4, 5, 5, 4, 3, 7]
        this.abnormal = [1, 2, 1, 1, 1, 1, 2]
        this.Standby = [12, 8, 9, 9, 9, 9, 10]
      }
      if (day === '23') {
        this.xAxis = ['16', '17', '18', '19', '20', '21', '22']
        this.processing = [11, 10, 10, 11, 12, 6, 6]
        this.shutdown = [4, 5, 5, 4, 3, 7, 6]
        this.abnormal = [1, 1, 1, 1, 1, 2, 1]
        this.Standby = [9, 9, 9, 9, 9, 10, 12]
      }
      if (day === '24') {
        this.xAxis = ['17', '18', '19', '20', '21', '22', '23']
        this.processing = [10, 10, 11, 12, 6, 6, 11]
        this.shutdown = [5, 5, 4, 3, 7, 6, 4]
        this.abnormal = [1, 1, 1, 1, 2, 1, 1]
        this.Standby = [9, 9, 9, 9, 10, 12, 9]
      }
      if (day === '25') {
        this.xAxis = ['18', '19', '20', '21', '22', '23', '24']
        this.processing = [10, 11, 12, 6, 6, 11, 10]
        this.shutdown = [5, 4, 3, 7, 6, 4, 5]
        this.abnormal = [1, 1, 1, 2, 2, 1, 1]
        this.Standby = [9, 9, 9, 10, 12, 9, 9]
      }
      if (day === '26') {
        this.xAxis = ['19', '20', '21', '22', '23', '24', '25']
        this.processing = [11, 12, 6, 6, 11, 10, 10]
        this.shutdown = [4, 3, 7, 6, 4, 5, 5]
        this.abnormal = [1, 1, 2, 2, 1, 1, 1]
        this.Standby = [9, 9, 10, 12, 9, 9, 9]
      }
      if (day === '27') {
        this.xAxis = ['20', '21', '22', '23', '24', '25', '26']
        this.processing = [12, 6, 6, 11, 10, 10, 11]
        this.shutdown = [3, 7, 6, 4, 5, 5, 4]
        this.abnormal = [1, 2, 2, 1, 1, 1, 1]
        this.Standby = [9, 10, 12, 9, 9, 9, 9]
      }
      if (day === '28') {
        this.xAxis = ['21', '22', '23', '24', '25', '26', '27']
        this.processing = [6, 6, 11, 10, 10, 11, 12]
        this.shutdown = [7, 5, 4, 4, 5, 4, 3]
        this.abnormal = [2, 2, 1, 1, 1, 1, 1]
        this.Standby = [10, 12, 9, 9, 9, 9, 9]
      }
      if (day === '29') {
        this.xAxis = ['22', '23', '24', '25', '26', '27', '28']
        this.processing = [6, 11, 10, 10, 11, 12, 6]
        this.shutdown = [5, 4, 5, 5, 4, 3, 7]
        this.abnormal = [2, 1, 1, 1, 1, 1, 2]
        this.Standby = [12, 9, 9, 9, 9, 9, 10]
      }
      if (day === '30') {
        this.xAxis = ['23', '24', '25', '26', '27', '28', 29]
        this.processing = [11, 10, 10, 11, 12, 6, 6]
        this.shutdown = [4, 5, 5, 4, 3, 7, 5]
        this.abnormal = [1, 1, 1, 1, 1, 2, 2]
        this.Standby = [9, 9, 9, 9, 9, 10, 12]
      }
    },
    dateFormat (fmt, date) {
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString() // 秒
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
        };
      };
      return fmt
    }
  }
}
</script>

<style>
  .stateBar, .stateBarparent{
    position: relative;
    width: 100%;
    height: 100%;
  }
  .towfour{
    position: absolute;
    width: 17px;
    height: 20px;
    top: 15%;
    left: 3%;
    font-size: 10px;
    background-color: #000000;
    color: #6e7079;
  }
</style>
