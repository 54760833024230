import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/index.css'
Vue.use(ElementUI)
Vue.config.productionTip = false

const common = {
  getNowTime () {
    const H = new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours()
    const M = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
    const S = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
    return H + ':' + M + ':' + S
  }
}
Vue.prototype.$common = common

new Vue({
  render: h => h(App)
}).$mount('#app')
