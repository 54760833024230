<template>
  <div class="datamoudle">
    <!-- 头部 -->
    <div class="header">
    </div>
    <!-- 中心数据模块 -->
    <div class="main">
      <Left/>
      <Center/>
      <Right/>
    </div>
    <!-- 底部logo -->
    <div class="footer">
    </div>
  </div>
</template>

<script>
import Left from './center_content/left.vue'
import Center from './center_content/center.vue'
import Right from './center_content/right.vue'
export default {
  name: 'dataMoudle',
  data () {
    return {
    }
  },
  components: {
    Left,
    Center,
    Right
  }
}
</script>
<style>
.datamoudle , .header , .main{
  position: absolute;
  top: 0;
  left: 0;
}
.datamoudle{
  width: 100%;
  height: 100%;
  background: url('../assets/img/starry_sky/网格@2x.png') no-repeat;
  background-position: 50% 50%;
}
.header{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 1687px;
  height: 48px;
  margin-top: 18px;
  background: url('../assets/img/header/头部@2x.png') no-repeat;
}
.footer{
  width: 100%;
  height: 61px;
  position: absolute;
  bottom: 0;
  background: url('../assets/img/footer/曲线 拷贝@2x.png');
}
</style>
