<template>
  <div class="componet-list">
        <ul class="list list-header">
            <li><span class="list-title">企业名称</span><span class="list-type">企业类型</span></li>
        </ul>
      <vue-seamless-scroll :data="listData" class="seamless-warp" :class-option="classOption">
        <ul class="list">
            <li v-for="(item, index) in listData" :key="index">
                <span class="list-title" v-text="item.orgName"></span><span class="list-type" v-text="item.type"></span>
            </li>
        </ul>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import orgList from '../../assets/json/org.json'
export default {
  name: 'Organization',
  components: {
    vueSeamlessScroll
  },
  data () {
    return {
      listData: orgList.orgList,
      classOption: {
        step: 0.3
      }
    }
  }
}
</script>

<style>

</style>
