<template>
  <div class="five_device">
        <div class="device_data">
      <div class="data_name">
        <span class="data_name_text">设备名称:</span>
        <span class="data_name_text">设备状态:</span>
        <span class="data_name_text">B抢焊接电压:</span>
        <span class="data_name_text">B枪引弧电压:</span>
      </div>
      <div class="data_detail">
        <span class="data_detail_text">堆焊机</span>
        <span class="data_state_text" :class="deviceState">{{state}}</span>
        <span class="data_detail_text">35V</span>
        <span class="data_detail_text">32V</span>
      </div>
    </div>
    <div class="device_charts_one">
      <Gaugefirst :typename="typename[0]"/>
    </div>
    <div class="device_charts_two">
      <Gaugetwo :typename="typename[1]"/>
    </div>
  </div>
</template>

<script>
import Gaugefirst from '../device_charts/gauge_five_first.vue'
import Gaugetwo from '../device_charts/gauge_five_second.vue'
export default {
  name: 'five',
  data () {
    return {
      deviceState: 'device_green',
      state: '运行中',
      typename: ['B枪送丝速度', 'B枪引弧送丝速度']
    }
  },
  components: {
    Gaugefirst,
    Gaugetwo
  }
}
</script>
