<template>
    <div class="water-polo">
        <div class="water-box">
            <div class="alarm-box">
                <div class="alarm-line alarm-line1"></div>
                <div :style="{height:'60px', width:'60px'}" ref="water1" class="alarm-bar"></div>
            </div>
            <span>系统并发</span>
        </div>
        <div class="water-box">
            <div class="alarm-box">
                <div class="alarm-line alarm-line2"></div>
                <div :style="{height:'60px', width:'60px'}" ref="water2" class="alarm-bar"></div>
            </div>
            <span>网络吞吐量</span>
        </div>
        <div class="water-box">
            <div class="alarm-box">
                <div class="alarm-line alarm-line1"></div>
                <div :style="{height:'60px', width:'60px'}" ref="water3" class="alarm-bar"></div>
            </div>
            <span>内存利用率</span>
        </div>
        <div class="water-box">
            <div class="alarm-box">
                <div class="alarm-line alarm-line2"></div>
                <div :style="{height:'60px', width:'60px'}" ref="water4" class="alarm-bar"></div>
            </div>
            <span>CPU利用率</span>
        </div>
        <div class="water-box">
            <div class="alarm-box">
                <div class="alarm-line alarm-line1"></div>
                <div :style="{height:'60px', width:'60px'}" ref="water5" class="alarm-bar"></div>
            </div>
            <span>查询并发量</span>
        </div>
    </div>
</template>

<script>
import { getmonitor } from '@/api/device_port.js'
import * as echarts from 'echarts/lib/echarts'
import 'echarts-liquidfill'
const color1 = new echarts.graphic.LinearGradient(1,
  0, 0, 1, [{
    offset: 0,
    color: 'rgba(255, 235, 157, 1)'
  }, {
    offset: 1,
    color: 'rgba(255, 251, 246, 1)'
  }])
const color2 = new echarts.graphic.LinearGradient(1,
  0, 0, 1, [{
    offset: 0,
    color: 'rgba(243, 152, 0, 1)'
  }, {
    offset: 1,
    color: 'rgba(253, 222, 104, 1)'
  }])
export default {
  name: 'Water',
  data () {
    return {
      system: 0,
      network: 0,
      memory: 0,
      cpu: 0,
      query: 0
    }
  },
  mounted () {
    this.getData()
    const textColor1 = 'rgba(246, 246, 246, 1)'
    const textColor2 = 'rgba(240, 142, 3, 1)'
    const ref1 = this.$refs.water1
    const ref2 = this.$refs.water2
    const ref3 = this.$refs.water3
    const ref4 = this.$refs.water4
    const ref5 = this.$refs.water5
    this.drawWater(color2, ref1, textColor1, this.system)
    this.drawWater(color1, ref2, textColor2, this.network)
    this.drawWater(color2, ref3, textColor1, this.memory)
    this.drawWater(color1, ref4, textColor2, this.cpu)
    this.drawWater(color2, ref5, textColor1, this.query)
    setInterval(() => {
      this.getData()
      this.drawWater(color2, ref1, textColor1, this.system)
      this.drawWater(color1, ref2, textColor2, this.network)
      this.drawWater(color2, ref3, textColor1, this.memory)
      this.drawWater(color1, ref4, textColor2, this.cpu)
      this.drawWater(color2, ref5, textColor1, this.query)
    }, 15000)
  },
  methods: {
    drawWater (color, ref, textColor, data) {
      const water = echarts.init(ref)
      const option = {
        series: [{
          type: 'liquidFill',
          radius: '99%', // 水球图的半径
          shape: 'circle',
          phase: 0, // 波的相位弧度 不设置  默认自动
          direction: 'right', // 波浪移动的速度  两个参数  left 从右往左 right 从左往右
          outline: {
            show: true,
            borderDistance: 0, // 边框线与图表的距离 数字
            itemStyle: {
              opacity: 0.5, // 边框的透明度   默认为 1
              borderWidth: 1, // 边框的宽度
              //   shadowBlur: 5, // 边框的阴影范围 一旦设置了内外都有阴影
              //   shadowColor: '#eee', // 边框的阴影颜色,
              borderColor: 'rgba(255, 251, 246, 0.2)' // 边框颜色
            }
          },
          // 图形样式
          itemStyle: {
            color: color, // 水球显示的背景颜色
            opacity: 1, // 波浪的透明度
            shadowBlur: 5, // 波浪的阴影范围
            shadowColor: 'rgba(255, 251, 246, 1)'
          },
          backgroundStyle: {
            color: '#000', // 水球未到的背景颜色
            opacity: 0.5
          },
          // 图形的高亮样式
          emphasis: {
            itemStyle: {
              opacity: 0.8 // 鼠标经过波浪颜色的透明度
            }
          },
          // 图形上的文本标签
          label: {
            fontSize: 15,
            baseline: 'bottom',
            fontWeight: 500,
            insideColor: textColor,
            color: textColor
          },
          data: [data] // 系列中的数据内容数组
        }]
      }
      water.clear()
      water.setOption(option)
    },
    async getData () {
      const { data: ret } = await getmonitor()
      if (ret.code === 200) {
        this.cpu = ret.data.cpu / 100
        this.memory = ret.data.memory / 100
        this.network = ret.data.network / 100
        this.query = ret.data.query / 100
        this.system = ret.data.system / 100
      }
      // const H = new Date().getHours()
      // const M = new Date().getMinutes()
      // const system = parseFloat((Math.random() * 10 + 60).toFixed(1))
      // let network = parseFloat((Math.random() * 5 + 15).toFixed(1))
      // const memory = parseFloat((Math.random() * 30 + 50).toFixed(1))
      // const cpu = 130 - memory + Math.random() * 10
      // const query = parseFloat((Math.random() * 20 + 40).toFixed(1))
      // if (H >= 9 && H <= 17 && H !== 13 && H !== 14) {
      //   network = parseFloat((Math.random() * 10 + 70).toFixed(1))
      // }
      // if ((H === 11 && M > 30) || H === 13 || H === 14) {
      //   network = parseFloat((Math.random() * 10 + 60).toFixed(1))
      // }
      // this.system = system / 100
      // this.memory = memory / 100
      // this.cpu = cpu / 100
      // this.query = query / 100
      // this.network = network / 100
    }
  }
}
</script>

<style scoped>
.water-polo {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.water-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.water-box span {
    color: rgba(246, 246, 246, 1);
    font-size: 12px;
    margin-top: 12px;
}
.alarm-box {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: rgba(255, 251, 246, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}
.alarm-box .alarm-line {
    position: absolute;
    width: 70px;
    height: 70px;
    /* transform:rotate(49deg); */
    border-radius: 50%;
}
.alarm-box .alarm-line1 {
    border: 1px solid rgba(243, 152, 0, 1);
}
.alarm-box .alarm-line2 {
    border: 1px solid rgba(255, 251, 246, 1);
}
</style>
