<template>
  <div class="two_device">
        <div class="device_data">
      <div class="data_name">
        <span class="data_name_text">设备名称:</span>
        <span class="data_name_text">设备状态:</span>
        <span class="data_name_text">正在加热:</span>
        <!-- <span class="data_name_text">循环时间:</span> -->
      </div>
      <div class="data_detail">
        <span class="data_detail_text">灌装机</span>
        <span class="data_state_text" :class="deviceState">{{state}}</span>
        <span class="data_detail_text device_green">A</span>
        <!-- <span class="data_detail_text">循环时间</span> -->
      </div>
    </div>
    <div class="device_charts_one">
      <Gaugefirst :typename="typename[0]"/>
    </div>
    <div class="device_charts_two">
      <Gaugetwo :typename="typename[1]"/>
    </div>
  </div>
</template>

<script>
import Gaugefirst from '../device_charts/gauge_two_first.vue'
import Gaugetwo from '../device_charts/gauge_two_second.vue'
export default {
  name: 'two',
  data () {
    return {
      deviceState: 'device_green',
      state: '运行中',
      typename: ['A测温度', 'B测温度']
    }
  },
  components: {
    Gaugefirst,
    Gaugetwo
  }
}
</script>
