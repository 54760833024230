<template>
  <div class="Sce">
    <Bgc/>
    <Datamoudle/>
  </div>
</template>

<script>

import Bgc from '@/components/background.vue'
import Datamoudle from '@/components/DataMoudle.vue'

export default {
  name: 'LSD',
  components: {
    Bgc,
    Datamoudle
  }
}
</script>
