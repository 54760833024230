import { createAPI } from '@/untils/request'
// 设备信息图表
export const getDevice = data => createAPI('deviceCnt', 'get', data)
// 服务监控
export const getmonitor = data => createAPI('monitor', 'get', data)
// 平台接口调用数
export const getcallInterface = data => createAPI('callInterface', 'get', data)
// 报警查询 alarms
export const getalarms = data => createAPI('alarms', 'get', data)
// 流量及消息数查询
export const getmsgrate = data => createAPI('msgrate ', 'get', data)
// 设备参数趋势
export const getmonthParams = data => createAPI('monthParams ', 'get', data)
