<template>
  <div class="four_device">
        <div class="device_data">
      <div class="data_name">
        <span class="data_name_text">设备名称:</span>
        <span class="data_name_text">设备状态:</span>
        <span class="data_name_text">程序号:</span>
        <span class="data_name_text">循环时间:</span>
      </div>
      <div class="data_detail">
        <span class="data_detail_text">上料桁架</span>
        <span class="data_state_text" :class="deviceState">{{state}}</span>
        <span class="data_detail_text">程序号</span>
        <span class="data_detail_text">循环时间</span>
      </div>
    </div>
    <div class="device_charts_one">
      <Gaugefirst/>
    </div>
    <div class="device_charts_two">
      <Gaugetwo/>
    </div>
  </div>
</template>

<script>
import Gaugefirst from '../device_charts/gauge_one_first.vue'
import Gaugetwo from '../device_charts/gauge_two.vue'
export default {
  name: 'four',
  data () {
    return {
      deviceState: 'device_yellow',
      state: '空闲中'
    }
  },
  components: {
    Gaugefirst,
    Gaugetwo
  }
}
</script>
