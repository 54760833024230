<template>
  <div class="backgroud">
    <div class="production_line">
      <!-- 看板 -->
      <div class="billboard">
        <div class="billboard_orange">
          <div class="orange1"></div>
          <div class="orange2"></div>
          <div class="orange3"></div>
          <div class="orange4"></div>
          <div class="orange5"></div>
        </div>
        <div class="billboard_white"></div>
        <div class="billboard_gray"></div>
      </div>
      <!-- 矩形灯光柱 -->
      <div class="rectangle_light_source">
        <div class="light"></div>
        <div class="rectangle"></div>
        <div class="rectangle_header_footer"></div>
        <div class="light_source"></div>
      </div>
    </div>
    <!-- 北斗七星北极星 -->
    <div class="TrionesRotate">
      <div class="Triones"></div>
      <div class="Polaris"></div>
    </div>
    <!-- 底部扩散圆 -->
    <div class="circle">
      <div class="one"></div>
      <div class="two"></div>
      <div class="three"></div>
      <div class="pulse"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bgc',
  data () {
    return {

    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      var width = document.body.clientWidth - 20
      var height = document.body.clientHeight - 20
      var bgc = document.querySelector('.backgroud')
      var Triones = document.querySelector('.Triones')
      // 北极星坐标
      var TrionesLocation = [
        [70, 40],
        [250, 30],
        [335, 100],
        [450, 165],
        [660, 305],
        [460, 280],
        [710, 180]
      ]
      // 随机生成星星
      for (var i = 0; i < 70; i++) {
        // 大星星
        var div = document.createElement('img')
        bgc.appendChild(div)
        div.className = 'xximg'
        div.src = '../assets/img/starry_sky/big.png'
        div.style.left = Math.random() * width + 'px'
        div.style.top = Math.random() * height + 'px'
        div.style.animationDelay = Math.random() * 5 + 's' // 添加动画延迟时间
        // 小星星
        var smallxx = document.createElement('img')
        bgc.appendChild(smallxx)
        smallxx.className = 'smallxximg'
        smallxx.src = '../assets/img/starry_sky/small.png'
        smallxx.style.left = Math.random() * width + 'px'
        smallxx.style.top = Math.random() * height + 'px'
        smallxx.style.animationDelay = Math.random() * 5 + 's'
      }
      // 生成北斗七星
      for (var idx = 0; idx < 7; idx++) {
        var xx = document.createElement('div')
        xx.className = 'Trionesxx'
        xx.style.left = TrionesLocation[idx][0] / 3.5 + 'px'
        xx.style.top = TrionesLocation[idx][1] / 3.5 + 'px'
        Triones.appendChild(xx)
      }
      // 随机生成流星
      for (var index = 0; index < 5; index++) {
        var lx = document.createElement('div')
        lx.className = 'star'
        lx.style.right = (Math.random() * 400 + 150) + 'px'
        lx.style.top = (Math.random() * 200 + 100) + 'px'
        lx.style.animationDelay = Math.random() * 3 * index + 's' // 添加动画延迟时间
        bgc.appendChild(lx)
      }
    }
  }
}
</script>

<style>
    * {
      margin: 0;
      padding: 0;
    }
    body, .backgroud {
      width: 100vw;
      height: 100vh;
    }
    .billboard{
      position: absolute;
      top: 18%;
      right: 7%;
      width: 135px;
      height: 220px;
    }
    .light{
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translateX(-50%);
      width: 35px;
      height: 43px;
      background: url('../assets/img/starry_sky/矩形4.png') no-repeat;
      animation: light 4s linear infinite;
    }
    @keyframes light {
      0%{
        opacity: 0.8;
      }
      50%{
        opacity: 0.1;
      }
      100%{
        opacity: 0.8;
      }
    }
    .orange1{
      position: absolute;
      top:10%;
      left: 10%;
      width: 87px;
      height: 55px;
      background: url('../assets/img/starry_sky/看板4.png') no-repeat;
      opacity: 1;
    }
    .orange2{
      position: absolute;
      top:20%;
      left: 10%;
      width: 87px;
      height: 55px;
      background: url('../assets/img/starry_sky/看板4.png') no-repeat;
      opacity: 1;
    }
    .orange3{
      position: absolute;
      top:50%;
      left: 10%;
      width: 87px;
      height: 55px;
      background: url('../assets/img/starry_sky/看板4.png') no-repeat;
      opacity: 1;
    }
    .orange4{
      position: absolute;
      top:60%;
      left: 10%;
      width: 87px;
      height: 55px;
      background: url('../assets/img/starry_sky/看板4.png') no-repeat;
      opacity: 1;
    }
    .orange5{
      position: absolute;
      top:70%;
      left: 10%;
      width: 87px;
      height: 55px;
      background: url('../assets/img/starry_sky/看板4.png') no-repeat;
      opacity: 1;
    }
    .billboard_orange{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 115px;
      height: 211px;
      background: url('../assets/img/starry_sky/看板1.png') no-repeat;
      animation: fluctuation 3s linear infinite;
    }
    @keyframes fluctuation {
      0%{
        transform: translateY(0);
      }
      25%{
        transform: translateY(5%);
      }
      50%{
        transform: translateY(0);
      }
      75%{
        transform: translateY(-5%);
      }
      100%{
        transform: translateY(0);
      }
    }
    .billboard_white{
      position: absolute;
      top: 4px;
      right: 8px;
      width: 115px;
      height: 211px;
      background: url('../assets/img/starry_sky/看板3.png') no-repeat;
      opacity: 0.5;
      animation: fluctuation 5s linear infinite;

    }
    .billboard_gray{
      position: absolute;
      top: 0;
      right: 0;
      width: 115px;
      height: 211px;
      background: url('../assets/img/starry_sky/看板2.png') no-repeat;
      animation: fluctuation 4s linear infinite;
    }
    .rectangle_light_source{
      position: absolute;
      top: 40%;
      right: 0;
      width: 45px;
      height: 170px;
    }
    .rectangle{
      position: absolute;
      bottom: 0;
      width: 45px;
      height: 134px;
      background: url('../assets/img/starry_sky/矩形.png') no-repeat;
    }
    .rectangle_header_footer{
      position: absolute;
      bottom: -1px;
      width: 45px;
      height: 141px;
      background: url('../assets/img/starry_sky/矩形2.png') no-repeat;
    }
    .light_source{
      position: absolute;
      top: 2px;
      left: 1px;
      width: 45px;
      height: 36px;
      background: url('../assets/img/starry_sky/矩形3.png') no-repeat;
    }
    .pulse {
      position: absolute;
      top: 1%;
      left: 6%;
      transform: translate(-50%, -50%);
      width: 480px;
      height: 480px;
      border: 10px solid #fdde68;
      border-radius: 50%;
      z-index: 1;
      opacity: 0;
      animation: warn 3s linear;
      animation-iteration-count: infinite
    }
    .one {
      position: absolute;
      top: 1%;
      left: 6%;
      transform: translate(-50%, -50%);
      width: 480px;
      height: 480px;
      border: 10px solid #228cb3;
      border-radius: 50%;
      z-index: 1;
      opacity: 0;
      animation: warn 5s linear;
      animation-iteration-count: infinite
    }
    .two {
      position: absolute;
      top: 1%;
      left: 6%;
      transform: translate(-50%, -50%);
      width: 480px;
      height: 480px;
      border: 10px solid #228cb3;
      border-radius: 50%;
      z-index: 1;
      opacity: 0;
      animation: warn 4s linear;
      animation-iteration-count: infinite
    }
    .two {
      position: absolute;
      top: 1%;
      left: 6%;
      transform: translate(-50%, -50%);
      width: 480px;
      height: 480px;
      border: 10px solid #228cb3;
      border-radius: 50%;
      z-index: 1;
      opacity: 0;
      animation: warn 2s linear;
      animation-iteration-count: infinite
    }
        @keyframes warn {
      0% {
        transform: scale(0);
        opacity: 0.0;
      }

      50% {
        transform: scale(0.3);
        opacity: 0.5;
      }

      100% {
        transform: scale(1.2);
        opacity: 0.0;
      }
    }
    .backgroud {
      position: relative;
      background-color: #000;
      overflow: hidden;
      perspective: 500px;
    }
    .production_line{
      position: absolute;
      top: 43%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 680px;
      height: 510px;
      background: url('../assets/img/starry_sky/产线.png') no-repeat;
    }
    .TrionesRotate{
      position: relative;
      top: 10%;
      left: 50%;
      width: 500px;
      height: 170px;
    }
    .Polaris{
      position: absolute;
      top: 65px;
      left: 48%;
      transform: translateX(-50%);
      width: 22px;
      height: 22px;
      background: url('../assets/img/starry_sky/北极星.png') no-repeat;
      animation: myStar 4s infinite linear;
      z-index: 1;
    }
    .Triones{
      position: absolute;
      bottom: 0;
      width: 250px;
      height: 100px;
      animation: spin1 80s infinite linear;
      transform-origin: top right;
    }
    @keyframes spin1 {
      from {
        transform: rotate(360deg)
      }

      to {
        transform: rotate(0deg)
      }
    }
    .Trionesxx{
      position: absolute;
      width: 14px;
      height: 12px;
      background: url('../assets/img/starry_sky/北斗七星.png') no-repeat;
    }
    .smallxximg {
      width: 16px;
      height: 16px;
      opacity: 0;
      position: absolute;
      background: url('../assets/img/starry_sky/big.png') no-repeat;
      left: 0px;
      top: 0px;
      animation: myStar 2s ease-in-out infinite;
      z-index: -1;
    }
    .xximg {
      width: 8px;
      height: 8px;
      opacity: 0;
      position: absolute;
      background: url('../assets/img/starry_sky/small.png') no-repeat;
      left: 0px;
      top: 0px;
      animation: myStar 2s ease-in-out infinite;
      z-index: -1;
    }

    @keyframes myStar {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    .star {
      width: 1px;
      height: 1px;
      display: block;
      position: absolute;
      border-color: transparent transparent transparent rgba(18, 39, 161, 0.514);
      opacity: 0;
      animation: star 4s linear infinite;
    }

    .star::after {
      content: '';
      display: block;
      border: 1px solid #fff;
      border-width: 0px 120px 3px 120px;
      border-color: transparent transparent transparent rgba(205, 204, 207, 0.596);
      transform: rotate(-45deg);
    }

    @keyframes star {
      0% {
        opacity: 0;
      }

      30% {
        opacity: 1;
      }

      100% {
        opacity: 0;
        transform: translate(-500px, 300px);
      }
    }
  .circle{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px;
    height: 600px;
    transform: translate(-50%, -50%) rotateX(258deg);
  }
</style>
