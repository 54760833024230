<template>
  <div class="componet-list exception device">
        <ul class="list list-header">
            <li>
                <span class="">公司名称</span>
                <span class="">设备</span>
                <span class="list-time">时间</span>
                <span class="list-status">状态</span>
            </li>
        </ul>
      <vue-seamless-scroll :data="listData" class="seamless-warp" :class-option="classOption">
        <ul class="list">
            <li v-for="(item, index) in listData" :key="index">
                <span class="" v-text="item.orgName"></span>
                <span class="" v-text="item.name"></span>
                <span class="list-time" v-text="item.time"></span>
                <span :class="{'list-status':true, 'list-status-white':item.status == '连接','list-status-yellow':item.status == '断开'}" v-text="item.status"></span>
            </li>
        </ul>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import orgList from '../../assets/json/org.json'
export default {
  name: 'Organization',
  components: {
    vueSeamlessScroll
  },
  data () {
    return {
      statusList: ['连接', '断开'],
      listData: [],
      classOption: {
        step: 0.3
      }
    }
  },
  methods: {
    getListData () {
      const number = [2, 3, 4]
      const length = number[Math.floor(Math.random() * number.length)]
      for (let i = 1; i < length; i++) {
        this.setListData()
      }
    },
    setListData () {
      const org = orgList.orgList[Math.floor(Math.random() * orgList.orgList.length)]
      const equipment = orgList.equipment[Math.floor(Math.random() * orgList.equipment.length)]
      const status = this.statusList[Math.floor(Math.random() * this.statusList.length)]
      if (this.listData.length > 10) {
        this.listData.splice(0, 3)
      }
      this.listData.push({
        orgName: org.orgName,
        name: equipment,
        time: this.$common.getNowTime(),
        status: status
      })
    }
  },
  created () {
    for (let i = 1; i < 8; i++) {
      this.setListData()
    }
    setInterval(() => {
      this.getListData()
    }, 15000)
  }
}
</script>

<style>
.componet-list.exception.device .list>li span {
    width: 20%;
    margin-left: 15px !important;
    /* text-align: center; */
}
</style>
