<template>
  <div class="flow_chart_div">

  </div>
</template>

<script>
import { getmsgrate } from '@/api/device_port.js'
import * as echarts from 'echarts'
export default {
  data () {
    return {
      rateCnt: [],
      rateday: []
    }
  },
  mounted () {
    this.init()
    setInterval(() => {
      this.init()
    }, 15000)
  },
  methods: {
    async init () {
      const { data: ret } = await getmsgrate()
      if (ret.code === 200) {
        this.rateCnt = []
        this.rateday = []
        var rateVoList = ret.data.rateVoList
        for (let i = 6; i >= 0; i--) {
          this.rateCnt.push((rateVoList[i].rateCnt).toFixed())
          this.rateday.push(rateVoList[i].day)
        }
      }
      var chartDom = document.querySelector('.flow_chart_div')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '20%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,

          data: this.rateday
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255,251,246,.3)',
              type: 'dashed'
            }
          },
          axisLine: {
            show: true
          }
        },
        series: [{
          data: this.rateCnt,
          type: 'line',
          smooth: true,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(240, 142, 3, 0.14)'
            }, {
              offset: 1,
              color: 'rgba(253, 222, 104,0.14)'
            }])
          },
          itemStyle: {
            normal: {
              color: 'rgb(253, 222, 104,0.14)',
              label: {
                show: true,
                position: 'top'
              },
              lineStyle: {
                color: '#d9d4ce'
              }
            }
          }
        }]
      }
      myChart.clear()
      option && myChart.setOption(option, true)
    }
  }
}
</script>

<style>
  .flow_chart_div{
    width: 100%;
    height: 100%;
  }
</style>
