<template>
  <div class="right">
    <div class="firm_list_warn">
      <div class="firm_list">
        <div class="firm_list_div">
          <Org />
        </div>
      </div>
      <div class="warning">
        <div class="title">
          <div class="warning_icon title_icon"></div>
          <div class="title_text">
            <div class="text">今日报警消息数</div>
            <countTo
              :startVal="warning_startVal"
              :endVal="warning_endVal"
              :duration="3000"
              style="font-family: MyFontName"
              class="number"
            ></countTo>
          </div>
        </div>
        <div class="warning_chart">
          <Alarm />
        </div>
      </div>
    </div>
    <div class="device_anomaly">
      <div class="device_anomaly_div">
        <Exc />
      </div>
    </div>
    <div class="platform_port">
      <div class="platform_port_div">
        <Interface />
      </div>
    </div>
    <div class="serve_monitor">
      <div class="serve_monitor_div">
        <Water />
      </div>
    </div>
  </div>
</template>

<script>
import { getalarms } from '@/api/device_port.js'
import Org from '../list/Organization.vue'
import Exc from '../list/ExceptionMsg.vue'
import Interface from '../chart/InterfaceCall.vue'
import Alarm from '../chart/AlarmCall.vue'
import Water from '../chart/WaterPolo.vue'
import countTo from 'vue-count-to'
export default {
  name: 'dataMoudle',
  data () {
    return {
      warning_startVal: 0,
      warning_endVal: 0,
      timer: null
    }
  },
  components: {
    countTo,
    Org,
    Exc,
    Interface,
    Alarm,
    Water
  },
  mounted () {
    this.init()
    setInterval(() => {
      this.init()
    }, 15000)
  },
  methods: {
    async init () {
      const { data: ret } = await getalarms()
      if (ret.code === 200) {
        this.warning_startVal = this.warning_endVal
        this.warning_endVal = ret.data.todayAlarmCnt
      }
    }
    // init () {
    //   clearInterval(this.timer)
    //   var random = Math.random()
    //   if (random < 0.5) {
    //     this.init()
    //     return
    //   }
    //   this.warning_endVal = parseInt(localStorage.getItem('todayWarningMsg')) || parseInt(1000 * random)
    //   this.timer = setInterval(() => {
    //     this.warning_startVal = this.warning_endVal
    //     this.warning_endVal += parseInt(Math.random() * (20 - 13) + 13)
    //     localStorage.setItem('todayWarningMsg', this.warning_endVal)
    //   }, 60000)
    // }
  }
}
</script>
