import { render, staticRenderFns } from "./InterfaceCall.vue?vue&type=template&id=54307640&"
import script from "./InterfaceCall.vue?vue&type=script&lang=js&"
export * from "./InterfaceCall.vue?vue&type=script&lang=js&"
import style0 from "./InterfaceCall.vue?vue&type=style&index=0&id=54307640&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports