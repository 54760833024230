<template>
  <div class="gauge_two_first">
    <div class="gauge_two_first_chart"></div>
    <div class="gauge_two_first_chart_name">{{typename}}</div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  data () {
    return {
      // type: '温度'
    }
  },
  props: {
    typename: {
      type: String
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      var chartDom = document.querySelector('.gauge_two_first_chart')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        series: [{
          type: 'gauge',
          min: 0,
          max: 240,
          splitNumber: 2,
          center: ['50%', '55%'],
          itemStyle: {
            color: '#F08E03',
            shadowColor: 'rgba(240, 142, 3, 1)'
            // opacity: 0
          },
          progress: {
            show: true,
            width: 7
          },
          axisLine: {
            lineStyle: {
              // color: '#05292A',
              color: [[1, '#292828']],
              width: 7,
              opacity: 0
            }
          },
          axisTick: {
            show: true,
            distance: -10,
            splitNumber: 50
          },
          pointer: {
            show: false
          },
          splitLine: {
            // distance: -22,
            show: false,
            length: 5,
            lineStyle: {
              width: 3
              // color: '#F08E03'
            }
          },
          axisLabel: {
            distance: 2,
            color: '#F08E03',
            fontSize: 10
          },
          anchor: {
            show: false
          },
          title: {
            show: false
          },
          detail: {
            valueAnimation: true,
            offsetCenter: [0, '5%'],
            formatter: function (value) {
              return '{value|' + value.toFixed(0) + '}{unit| °C}'
            },
            rich: {
              value: {
                fontSize: 16,
                fontWeight: 'bolder',
                color: '#FFFBF6'
              },
              unit: {
                fontSize: 10,
                color: '#FFFBF6'
              }
            }
          },
          data: [{
            value: 70
          }]
        }]
      }

      option && myChart.setOption(option)
    }
  }
}
</script>

<style>
.gauge_two_first,.gauge_two_first_chart  {
  position: relative;
  width: 100%;
  height: 100%;
}
.gauge_two_first_chart_name{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: white;
}
</style>
