<template>
  <div class="alarm-call">
    <div
      :style="{ height: '164px', width: '260px' }"
      ref="bar"
      class="alarm-bar"
    ></div>
  </div>
</template>

<script>
import { getalarms } from '@/api/device_port.js'
import * as echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/pictorialBar'
import 'echarts/lib/chart/bar'
// import 'echarts/lib/component/legend'
// import 'echarts/lib/component/grid'
export default ({
  name: 'Alarm',
  data () {
    return {
      sevenDays: [],
      dateList: []
    }
  },
  mounted () {
    this.drawBar()
    setInterval(() => {
      this.drawBar()
    }, 15000)
    // const nowDate = new Date().getDate()
    // let todayWarningMsg = localStorage.getItem('todayWarningMsg')
    // let sevenDays = JSON.parse(localStorage.getItem('sevenDays'))
    // if (!sevenDays) {
    //   sevenDays = this.getInitData()
    //   sevenDays.push({
    //     date: nowDate,
    //     value: todayWarningMsg
    //   })
    // } else {
    //   todayWarningMsg = localStorage.getItem('todayWarningMsg')
    //   if (nowDate !== sevenDays[6].date) {
    //     sevenDays.splice(0, 1)
    //   } else {
    //     sevenDays.splice(6, 1)
    //   }
    //   sevenDays.push({
    //     date: nowDate,
    //     value: todayWarningMsg
    //   })
    // }
    // this.sevenDays = sevenDays
    // this.dateList = this.getDateList()
    // this.drawBar()
    // setInterval(() => {
    //   // const H = new Date().getHours()
    //   // if (H >= 15) {
    //   //   localStorage.setItem('sevenDays', JSON.stringify(this.sevenDays))
    //   // }
    //   localStorage.setItem('sevenDays', JSON.stringify(this.sevenDays))
    //   todayWarningMsg = localStorage.getItem('todayWarningMsg')
    //   this.sevenDays.splice(6, 1)
    //   this.sevenDays.push({
    //     date: nowDate,
    //     value: todayWarningMsg
    //   })
    //   this.drawBar()
    // }, 30000)
  },
  methods: {
    // getBeforeDate (n) {
    //   let s = null
    //   const d = new Date()
    //   let year = d.getFullYear()
    //   let mon = d.getMonth() + 1
    //   let day = d.getDate()
    //   if (day <= n) {
    //     if (mon > 1) {
    //       mon = mon - 1
    //     } else {
    //       year = year - 1
    //       mon = 12
    //     }
    //   }
    //   d.setDate(d.getDate() - n)
    //   year = d.getFullYear()
    //   mon = d.getMonth() + 1
    //   day = d.getDate()
    //   s = year + '-' + (mon < 10 ? ('0' + mon) : mon) + '-' + (day < 10 ? ('0' + day) : day)
    //   return s
    // },
    // getDay (date) {
    //   return new Date(date).getDay()
    // },
    // getDateList () {
    //   const dateList = []
    //   for (let i = 0; i < this.sevenDays.length; i++) {
    //     dateList.push(this.sevenDays[6 - i].date)
    //   }
    //   return dateList
    // },
    // getInitData () {
    //   const one = this.getBeforeDate(6)
    //   const two = this.getBeforeDate(5)
    //   const three = this.getBeforeDate(4)
    //   const four = this.getBeforeDate(3)
    //   const five = this.getBeforeDate(2)
    //   const six = this.getBeforeDate(1)
    //   const oneDate = new Date(one).getDate()
    //   const twoDate = new Date(two).getDate()
    //   const threeDate = new Date(three).getDate()
    //   const fourDate = new Date(four).getDate()
    //   const fiveDate = new Date(five).getDate()
    //   const sixDate = new Date(six).getDate()
    //   const sevenDays = []
    //   let oneValue = null
    //   let twoValue = null
    //   let threeValue = null
    //   let fourValue = null
    //   let fiveValue = null
    //   let sixValue = null
    //   if (this.getDay(one) !== 0 && this.getDay(one) !== 6) {
    //     oneValue = parseInt(Math.random() * 4000 + 8000)
    //   } else {
    //     oneValue = parseInt(Math.random() * 1200 + 2400)
    //   }
    //   if (this.getDay(two) !== 0 && this.getDay(two) !== 6) {
    //     twoValue = parseInt(Math.random() * 4000 + 8000)
    //   } else {
    //     twoValue = parseInt(Math.random() * 1200 + 2400)
    //   }
    //   if (this.getDay(three) !== 0 && this.getDay(three) !== 6) {
    //     threeValue = parseInt(Math.random() * 4000 + 8000)
    //   } else {
    //     threeValue = parseInt(Math.random() * 1200 + 2400)
    //   }
    //   if (this.getDay(four) !== 0 && this.getDay(four) !== 6) {
    //     fourValue = parseInt(Math.random() * 4000 + 8000)
    //   } else {
    //     fourValue = parseInt(Math.random() * 1200 + 2400)
    //   }
    //   if (this.getDay(five) !== 0 && this.getDay(five) !== 6) {
    //     fiveValue = parseInt(Math.random() * 4000 + 8000)
    //   } else {
    //     fiveValue = parseInt(Math.random() * 1200 + 2400)
    //   }
    //   if (this.getDay(six) !== 0 && this.getDay(six) !== 6) {
    //     sixValue = parseInt(Math.random() * 4000 + 8000)
    //   } else {
    //     sixValue = parseInt(Math.random() * 1200 + 2400)
    //   }
    //   sevenDays.push({
    //     date: oneDate,
    //     value: oneValue
    //   }, {
    //     date: twoDate,
    //     value: twoValue
    //   }, {
    //     date: threeDate,
    //     value: threeValue
    //   }, {
    //     date: fourDate,
    //     value: fourValue
    //   }, {
    //     date: fiveDate,
    //     value: fiveValue
    //   }, {
    //     date: sixDate,
    //     value: sixValue
    //   })
    //   return sevenDays
    // },
    async drawBar () {
      const { data: ret } = await getalarms()
      console.log(ret)
      if (ret.code === 200) {
        this.sevenDays = []
        this.dateList = []
        var colorObj = {}
        this.sevenDays = ret.data.alarmInfos
        this.sevenDays.forEach((item, index) => {
          this.dateList.push(item.day)
          if (item.alarmCnt <= 5000) {
            const color = 'color' + index
            colorObj[color] = '#fffbf6'
          } else if (item.alarmCnt <= 10000 && item.alarmCnt >= 5000) {
            const color = 'color' + index
            colorObj[color] = '#fdde68'
          } else {
            const color = 'color' + index
            colorObj[color] = '#ff4f4f'
          }
        })
      }
      console.log(colorObj)
      const bar = echarts.init(this.$refs.bar)
      const option = {
        grid: [{ // 图形的位置
          top: 20,
          right: 45,
          left: 30,
          bottom: 25
        }],
        xAxis: {
          name: '（千）',
          nameTextStyle: {
            color: 'rgba(255, 251, 246,0.8)',
            verticalAlign: 'top',
            lineHeight: 26
          },
          show: true, // 是否展示X轴
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255, 251, 246,0.16)'
            }
          },
          splitLine: {
            lineStyle: {
              color: ['rgba(255, 251, 246,0.16)']
            }
          },
          axisLabel: {
            show: true,
            color: 'rgba(255, 251, 246,0.8)',
            formatter: function (value) {
              return value / 1000
            },
            verticalAlign: 'top'
            // rotate: 10
          }
        },
        yAxis: {
          data: this.dateList,
          show: true,
          axisLabel: {
            show: true,
            color: 'rgba(255, 251, 246,0.8)'
          },
          axisTick: {
            show: false// 不展示刻度
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(255, 251, 246,0.16)'
            }
          }
        },
        series: [
          {
            name: '',
            type: 'pictorialBar',
            symbol: 'roundRect',
            barWidth: '3%',
            barMaxWidth: 100,
            symbolOffset: [5, 0],
            symbolMargin: [2, '0'],
            symbolRepeat: true,
            symbolSize: [3, 8],
            symbolRotate: -30,
            data: [{
              value: this.sevenDays[0].alarmCnt,
              label: {
                show: true,
                position: 'right',
                color: '#fff'
              },
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    position: 'right',
                    color: 'rgba(250, 232, 206, 1)'
                  },
                  color: colorObj.color0
                }
              }
            }, {
              value: this.sevenDays[1].alarmCnt,
              label: {
                show: true,
                position: 'right',
                color: 'rgba(240, 142, 3, 1)'
              },
              itemStyle: {
                normal: {
                  color: colorObj.color1
                }
              }
            }, {
              value: this.sevenDays[2].alarmCnt,
              label: {
                show: true,
                position: 'right',
                color: 'rgba(250, 232, 206, 1)'
              },
              itemStyle: {
                normal: {
                  color: colorObj.color2
                }
              }
            }, {
              value: this.sevenDays[3].alarmCnt,
              label: {
                show: true,
                position: 'right',
                color: 'rgba(240, 142, 3, 1)'
              },
              itemStyle: {
                normal: {
                  color: colorObj.color3
                }
              }
            }, {
              value: this.sevenDays[4].alarmCnt,
              label: {
                show: true,
                position: 'right',
                color: 'rgba(250, 232, 206, 1)'
              },
              itemStyle: {
                normal: {
                  color: colorObj.color4
                }
              }
            }, {
              value: this.sevenDays[5].alarmCnt,
              label: {
                show: true,
                position: 'right',
                color: 'rgba(240, 142, 3, 1)'
              },
              itemStyle: {
                normal: {
                  color: colorObj.color5
                }
              }
            }, {
              value: this.sevenDays[6].alarmCnt,
              label: {
                show: true,
                position: 'right',
                color: 'rgba(250, 232, 206, 1)'
              },
              itemStyle: {
                normal: {
                  color: colorObj.color6
                }
              }
            }]
          }
        ]

      }
      bar.clear()
      bar.setOption(option, true)
    }
  }

})
</script>

<style scoped>
</style>
