<template>
  <div class="content">
    <div class="content_title">
      <div class="firm_num">
        <countTo
          :startVal="startVal"
          :endVal="firmendVal"
          :duration="3000"
          style="font-family: MyFontName"
          class="firm_number"
        ></countTo>
        <div class="firm_text">家</div>
      </div>
      <div class="user_num">
        <countTo
          :startVal="startVal"
          :endVal="endVal"
          :duration="3000"
          style="font-family: MyFontName"
          class="firm_number"
        ></countTo>
        <div class="firm_text">位</div>
      </div>
    </div>
    <div class="content_center"></div>
    <div class="content_footer">
      <Devicemessage />
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to'
import Devicemessage from '../chart/device_message/index.vue'
export default {
  name: 'dataMoudle',
  data () {
    return {
      startVal: 0,
      firmendVal: 108,
      endVal: 3295
    }
    // return {
    //   startVal: 0,
    //   firmendVal: 98,
    //   endVal: 3198
    // }
  },
  components: {
    countTo,
    Devicemessage
  }
}
</script>
