<template>
  <div id="app" ref="app">
    <!-- <Sce/> -->
    <Lsd />
  </div>
</template>

<script>
// import Sce from './views/Smart-China-Expo.vue'
// import Org from './components/list/Organization.vue'
// import Exc from './components/list/ExceptionMsg.vue'
// import Dev from './components/list/DeviceAccess.vue'
// import Interface from './components/InterfaceCall.vue'
// import Alarm from './components/AlarmCall.vue'
// import Water from './components/WaterPolo.vue'
import Lsd from './views/LSDvisual.vue'
export default {
  name: 'app',
  components: {
    Lsd
  }
}
</script>

<style>
</style>
