<template>
  <div class="three_device">
        <div class="device_data">
      <div class="data_name">
        <span class="data_name_text">设备名称:</span>
        <span class="data_name_text">设备状态:</span>
        <span class="data_name_text">校准后扭矩:</span>
      </div>
      <div class="data_detail">
        <span class="data_detail_text">铆合拉杆装配线</span>
        <span class="data_state_text" :class="deviceState">{{state}}</span>
        <span class="data_detail_text">程序号</span>
      </div>
    </div>
    <div class="device_charts_one">
      <Gaugefirst :typename="typename[0]"/>
    </div>
    <div class="device_charts_two">
      <Gaugetwo :typename="typename[1]"/>
    </div>
  </div>
</template>

<script>
import Gaugefirst from '../device_charts/gauge_three_first.vue'
import Gaugetwo from '../device_charts/gauge_three_second.vue'
export default {
  name: 'three',
  data () {
    return {
      deviceState: 'device_green',
      state: '运行中',
      typename: ['当次平均扭矩', '当次峰值扭矩']
    }
  },
  components: {
    Gaugefirst,
    Gaugetwo
  }
}
</script>
