<template>
  <div class="messgae_chart_div"></div>
</template>

<script>
import { getmsgrate } from '@/api/device_port.js'
import * as echarts from 'echarts'
export default {
  name: 'messageChart',
  data () {
    return {
      msgCnt: [],
      msgday: []
    }
  },
  mounted () {
    this.init()
    setInterval(async () => {
      this.init()
    }, 15000)
  },
  methods: {
    async init () {
      const { data: ret } = await getmsgrate()
      if (ret.code === 200) {
        this.msgCnt = []
        this.msgday = []
        var msgVoList = ret.data.msgVoList
        for (let i = 6; i >= 0; i--) {
          this.msgCnt.push((msgVoList[i].msgCnt / 1000).toFixed())
          this.msgday.push(msgVoList[i].day)
        }
      }
      var chartDom = document.querySelector('.messgae_chart_div')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        grid: {
          left: '1%',
          right: '2%',
          bottom: '1%',
          top: '15%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.msgday
        },
        yAxis: {
          type: 'value',
          // name: '万/条',
          axisTick: {
            show: true,
            lineStyle: {
              color: '#959595'
            }
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            showBackground: true,
            barWidth: 15,
            data: this.msgCnt,
            itemStyle: {
              normal: {
                color: function (params) {
                  var colorList = [
                    ['#FFFFFF', 'rgba(255, 251, 246, .5)'],
                    ['#F08E03', 'rgba(253, 222, 104, .5)'],
                    ['#FFFFFF', 'rgba(255, 251, 246, .5)'],
                    ['#F08E03', 'rgba(253, 222, 104, .5)'],
                    ['#FFFFFF', 'rgba(255, 251, 246, .5)'],
                    ['#F08E03', 'rgba(253, 222, 104, .5)'],
                    ['#FFFFFF', 'rgba(255, 251, 246, .5)']
                  ]

                  var index = params.dataIndex
                  if (params.dataIndex >= colorList.length) {
                    index = params.dataIndex - colorList.length
                  }

                  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: colorList[index][0] },
                    { offset: 1, color: colorList[index][1] }
                  ])
                }
              }
            },
            type: 'bar'
          }
        ]
      }
      myChart.clear()
      option && myChart.setOption(option)
    },
    dateFormat (fmt, date) {
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString() // 秒
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
          )
        }
      }
      return fmt
    }
  }
}
</script>

<style>
.messgae_chart_div {
  width: 100%;
  height: 100%;
}
</style>
