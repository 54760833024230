<template>
  <div class="left">
    <!-- 设备云监控 -->
    <div class="DeviceMonitoring">
      <div class="DeviceSum">
        <Active />
        <Noactive />
      </div>
      <div class="DeviceState">
        <div class="green">
          <div class="green_text">加工状态</div>
          <div class="green_icon">{{processing}}</div>
        </div>
        <div class="gray">
          <div class="gray_icon">{{shutdown}}</div>
          <div class="gray_text">关机状态</div>
        </div>
        <div class="DeviceState_chart">
          <Pie/>
        </div>
        <div class="orange">
          <div class="orange_text">待机状态</div>
          <div class="orange_icon">{{Standby}}</div>
        </div>
        <div class="red">
          <div class="red_icon">{{abnormal}}</div>
          <div class="red_text">异常状态</div>
        </div>
      </div>
    </div>
    <!-- 设备实时接入情况 -->
    <div class="DeviceAccess">
      <div class="DeviceAccess_div">
      <Dev/>
      </div>
    </div>
    <!-- 设备参数与设备状态时间 -->
    <div class="DeviceParameter">
      <div class="Parameter_left">
        <div class="title">
          <div class="title_icon Parameter_icon"></div>
          <div class="title_text">
            <div class="text">当前设备参数</div>
            <countTo
              :startVal="device_data_startVal"
              :endVal="device_data_endVal"
              :duration="3000"
              style="font-family: MyFontName"
              class="number"
            ></countTo>
          </div>
        </div>
        <div class="Parameter_chart">
          <Linechart/>
        </div>
      </div>
      <div class="Parameter_right">
        <Statebar/>
      </div>
    </div>
    <!-- 设备流量与消息 -->
    <div class="flow_message">
      <div class="flow">
        <div class="title">
          <div class="flow_icon title_icon"></div>
          <div class="title_text">
            <div class="text">平台今日流量</div>
            <countTo
              :startVal="flow_startVal"
              :endVal="flow_endVal"
              :duration="3000"
              style="font-family: MyFontName"
              class="number"
            ></countTo>
            <div style="font-family: MyFontName" class="flow_text">{{flow_unit}}</div>
          </div>
        </div>
        <div class="flow_chart">
          <Flowchart/>
        </div>
      </div>
      <div class="message">
        <div class="title">
          <div class="message_icon title_icon"></div>
          <div class="title_text">
            <div class="text">今日消息数量</div>
            <countTo
              :startVal="message_startVal"
              :endVal="message_endVal"
              :duration="3000"
              style="font-family: MyFontName"
              class="number"
            ></countTo>
            <div style="font-family: MyFontName" class="flow_text">{{message_unit}}</div>
          </div>
        </div>
        <div class="message_chart">
          <Messagechart/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getmsgrate, getmonthParams, getDevice } from '@/api/device_port.js'
import countTo from 'vue-count-to'
import Active from '../chart/active.vue'
import Noactive from '../chart/noactive.vue'
import Dev from '../list/DeviceAccess.vue'
import Pie from '../chart/pie.vue'
import Linechart from '../chart/LineChart.vue'
import Statebar from '../chart/stateBarChart.vue'
import Flowchart from '../chart/flow_chart.vue'
import Messagechart from '../chart/message_chart.vue'
export default {
  name: 'dataMoudle',
  data () {
    return {
      device_data_startVal: 0,
      device_data_endVal: 0,
      // 流量
      flow_startVal: 0,
      flow_endVal: 0,
      // 消息
      message_startVal: 0,
      message_endVal: 0,
      // 流量单位
      flow_unit: 'GB',
      bigFlow_endVal: 0,
      message_unit: '万',
      // 待机
      Standby: 0,
      // 异常
      abnormal: 0,
      // 关机
      shutdown: 0,
      // 加工
      processing: 0
    }
  },
  components: {
    countTo,
    Active,
    Noactive,
    Dev,
    Pie,
    Linechart,
    Statebar,
    Flowchart,
    Messagechart
  },
  created () {
    this.init()
    setInterval(() => {
      this.init()
    }, 15000)
  },
  methods: {
    async init () {
      const { data: ret } = await getmsgrate()
      const { data: sret } = await getmonthParams()
      const { data: tret } = await getDevice()
      if (tret.code === 200) {
        this.shutdown = tret.data.stop
        this.Standby = tret.data.free
        this.abnormal = tret.data.abnormal
        this.processing = tret.data.running
      }
      if (sret.code === 200) {
        this.device_data_startVal = this.device_data_endVal
        this.device_data_endVal = sret.data.currentCnt
      }
      if (ret.code === 200) {
        if (ret.data.todayMsgCnt > 20000) {
          this.message_unit = '万'
        } else {
          this.message_unit = '条'
        }
        this.message_startVal = this.message_endVal
        this.message_endVal = ret.data.todayMsgCnt / 10000
        if (ret.data.todayRateCnt < 1) {
          this.flow_unit = 'MB'
          this.flow_startVal = this.flow_endVal
          this.flow_endVal = parseInt(ret.data.todayRateCnt * 1024)
        } else {
          this.flow_unit = 'GB'
          this.flow_startVal = this.flow_endVal
          this.flow_endVal = ret.data.todayRateCnt
        }
      }
    }
    // init () {
    //   setInterval(() => {
    //     var arr = localStorage.getItem('devicestate').split(',')
    //     this.device_data_startVal = this.device_data_endVal
    //     this.device_data_endVal = parseInt(localStorage.getItem('activeDevice') * 0.92) * 15
    //     localStorage.setItem('device_data_endVal', this.device_data_endVal)
    //     this.processing = arr[0]
    //     this.Standby = arr[1]
    //     this.shutdown = arr[2]
    //     this.abnormal = arr[3]
    //   }, 2000)
    //   var date = new Date()
    //   var day = this.dateFormat('dd', date)
    //   var time = parseInt(this.dateFormat('HH', date))
    //   setTimeout(() => {
    //     if (day === '29' || day === '22') {
    //     // 周末晚上与早晨
    //       setInterval(() => {
    //         clearTimeout(this.smalltimer)
    //         if (time <= 8) {
    //           this.smalltimer = setInterval(() => {
    //             time = parseInt(this.dateFormat('HH', date))
    //             this.flow_startVal = this.flow_endVal
    //             this.flow_endVal = 0.5 + (Math.random() * 0.1) * 1000 / 1000
    //             this.bigFlow_endVal = 0.5 + (Math.random() * 0.1) * 1000 / 1000
    //             if (this.flow_endVal < 1) {
    //               this.flow_unit = 'MB'
    //               this.flow_endVal = parseInt(this.flow_endVal * 1024)
    //             } else {
    //               this.flow_unit = 'GB'
    //             }
    //             // localStorage.setItem('flow_data', this.flow_endVal)
    //             localStorage.setItem('flow_data', JSON.stringify({ day: day, data: this.flow_endVal }))
    //           }, 60000)
    //           // 周末上班时间
    //         } else {
    //           this.smalltimer = setInterval(() => {
    //             time = parseInt(this.dateFormat('HH', date))
    //             this.flow_endVal = this.bigFlow_endVal + Math.random() * (1.6475 - 1.0984) + 1.0984
    //             if (this.flow_endVal < 1) {
    //               this.flow_unit = 'MB'
    //               this.flow_endVal = parseInt(this.flow_endVal * 1024)
    //             } else {
    //               this.flow_unit = 'GB'
    //             }
    //             localStorage.setItem('flow_data', JSON.stringify({ day, data: this.flow_endVal }))
    //           }, 60000)
    //         }
    //       }, this.hourRefresh)
    //     } else {
    //     // 工作日早晨晚上
    //       setInterval(() => {
    //         if (time >= 18 || time <= 8) {
    //           this.smalltimer = setInterval(() => {
    //             time = parseInt(this.dateFormat('HH', date))
    //             this.flow_startVal = this.flow_endVal
    //             this.flow_endVal = 0.5 + (Math.random() * 0.1) * 1000 / 1000
    //             if (this.flow_endVal < 1) {
    //               this.flow_unit = 'MB'
    //               this.flow_endVal = parseInt(this.flow_endVal * 1024)
    //             } else {
    //               this.flow_unit = 'GB'
    //             }
    //             localStorage.setItem('flow_data', JSON.stringify({ day, data: this.flow_endVal }))
    //           }, 60000)
    //           // 工作日工作时间
    //         } else {
    //           this.smalltimer = setInterval(() => {
    //             time = parseInt(this.dateFormat('HH', date))
    //             this.flow_startVal = this.flow_endVal
    //             this.flow_endVal = parseInt(3295 * (Math.random() * (1 - 0.92) + 0.92))
    //             if (this.flow_endVal < 1) {
    //               this.flow_unit = 'MB'
    //               this.flow_endVal = parseInt(this.flow_endVal * 1024)
    //             } else {
    //               this.flow_unit = 'GB'
    //             }
    //             localStorage.setItem('flow_data', JSON.stringify({ day, data: this.flow_endVal }))
    //           }, 60000)
    //         }
    //       }, this.hourRefresh)
    //     }
    //   }, 1000)
    // },
    // 时间处理方法
    // dateFormat (fmt, date) {
    //   let ret
    //   const opt = {
    //     'Y+': date.getFullYear().toString(), // 年
    //     'm+': (date.getMonth() + 1).toString(), // 月
    //     'd+': date.getDate().toString(), // 日
    //     'H+': date.getHours().toString(), // 时
    //     'M+': date.getMinutes().toString(), // 分
    //     'S+': date.getSeconds().toString() // 秒
    //   }
    //   for (const k in opt) {
    //     ret = new RegExp('(' + k + ')').exec(fmt)
    //     if (ret) {
    //       fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
    //     };
    //   };
    //   return fmt
    // }
  }
}
</script>

<style>
  .flow_text{
    color: #F08E03;
    line-height: 40px;
    font-size: 16px;
  }
</style>
