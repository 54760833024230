import axios from 'axios'
const request = axios.create({
  baseURL: 'http://121.36.20.241/zbh1'
})
// 拦截器
request.interceptors.request.use(function (config) {
  config.headers = {
    ...config.headers
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// http
export const createAPI = (url, method, data) => {
  const config = {}
  if (method === 'get') {
    config.params = {
      ...data
    }
  } else {
    config.data = data
  }
  return request({
    url,
    method,
    ...config
  })
}
