<template>
  <div class="gauge_five_first">
    <div class="gauge_five_first_chart"></div>
    <div class="gauge_five_first_chart_name">{{typename}}</div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  data () {
    return {
    }
  },
  props: {
    typename: {
      type: String
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      var chartDom = document.querySelector('.gauge_five_first_chart')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        series: [{
          type: 'gauge',
          min: 0,
          max: 5000,
          splitNumber: 2,
          center: ['50%', '55%'],
          itemStyle: {
            color: '#F08E03',
            shadowColor: 'rgba(240, 142, 3, 1)'
          },
          progress: {
            show: true,
            width: 7
          },
          axisLine: {
            lineStyle: {
              // color: '#05292A',
              color: [[1, '#292828']],
              width: 7
            }
          },
          axisTick: {
            show: true,
            distance: -2
          },
          splitLine: {
            // distance: -22,
            show: false,
            length: 5,
            lineStyle: {
              width: 3
              // color: '#F08E03'
            }
          },
          axisLabel: {
            distance: 2,
            color: '#F08E03',
            fontSize: 10
          },
          anchor: {
            show: true,
            showAbove: true,
            size: 8,
            borderCap: 'round',
            itemStyle: {
              borderWidth: 8,
              borderColor: '#F08E03'
            }
          },
          title: {
            show: true
          },
          detail: {
            borderColor: '#999',
            borderWidth: 1,
            width: '180%',
            lineHeight: 10,
            height: 12,
            borderRadius: 8,
            offsetCenter: [0, '100%'],
            valueAnimation: true,
            formatter: function (value) {
              return '{value|' + value.toFixed(0) + '}{unit|mm/min}'
            },
            rich: {
              value: {
                fontSize: 16,
                fontWeight: 'bolder',
                color: '#777'
              },
              unit: {
                fontSize: 16,
                color: '#999',
                padding: [0, 0, 0, 0]
              }
            }
          },
          data: [{
            value: 3100
          }]
        }]
      }

      option && myChart.setOption(option)
    }
  }
}
</script>

<style>
.gauge_five_first,.gauge_five_first_chart  {
  position: relative;
  width: 100%;
  height: 100%;
}
.gauge_five_first_chart_name{
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
}
</style>
