<template>
  <div class="noactive">
    <countTo
      :startVal="startVal"
      :endVal="endVal"
      :duration="3000"
      style="font-family: MyFontName"
      class="number"
      suffix="台"
    ></countTo>
    <div class="fir_text">智能边缘终端</div>
    <el-progress
      class="no"
      type="circle"
      :percentage="percentage"
      :width="100"
      color="#979798"
    ></el-progress>
    <div class="progress_text">
      上云未激活:<span>{{ active }}</span>
    </div>
  </div>
</template>

<script>
import { getDevice } from '@/api/device_port.js'
import countTo from 'vue-count-to'
export default {
  name: 'dataMoudle',
  data () {
    return {
      startVal: 0,
      endVal: 465,
      active: 0,
      percentage: 0
    }
  },
  components: {
    countTo
  },
  mounted () {
    this.getNoActiveDevice()
    this.init()
    setInterval(() => {
      this.getNoActiveDevice()
      this.init()
    }, 15000)
  },
  methods: {
    async getNoActiveDevice () {
      const { data: ret } = await getDevice()
      if (ret.code === 200) {
        console.log('noactive', ret)
        this.active = ret.data.noActiveCnt
        this.percentage = parseInt(parseFloat(localStorage.getItem('noactvepercentage')).toFixed(2))
      }
    },
    init () {
      // this.active = parseInt(parseInt(localStorage.getItem('activeDevice')) * 0.08)
      var date = new Date()
      var datetime = this.dateFormat('YYYY-mm-dd', date)
      if (datetime === '2021-08-22') this.endVal++
      if (datetime === '2021-08-23') this.endVal += 2
      if (datetime === '2021-08-24') this.endVal += 3
      if (datetime === '2021-08-25') this.endVal += 4
      if (datetime === '2021-08-26') this.endVal += 5
      if (datetime === '2021-08-27') this.endVal += 6
      if (datetime === '2021-08-28') this.endVal += 7
      if (datetime === '2021-08-29') this.endVal += 8
      if (datetime === '2021-08-30') this.endVal += 9
      if (datetime === '2021-08-31') this.endVal += 10
      // setInterval(() => {
      //   this.active = parseInt(parseInt(localStorage.getItem('activeDevice')) * 0.08)
      // }, 500000)
    },
    dateFormat (fmt, date) {
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
        };
      };
      return fmt
    }
  }
}
</script>
