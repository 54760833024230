<template>
  <div class="device_message">
    <!-- 不同设备 -->
    <div class="device_nav">
      <div
        v-for="item in device"
        :key="item.id"
        class="item"
        :class="item.isactive ? `device_active ${item.device_icon}` : `${item.device_icon}`"
      >{{item.name}}</div>
    </div>
    <!-- 设备不同数据 -->
    <component class="device_list" :is="name"></component>
  </div>
</template>

<script>
import one from './moudle/one_device.vue'
import two from './moudle/two_device.vue'
import three from './moudle/three_device.vue'
import four from './moudle/four_device.vue'
import five from './moudle/five_device.vue'
export default {
  name: 'deviceMessage',
  data () {
    return {
      device: [
        {
          name: '卧式加工中心',
          isactive: true,
          com: 'one',
          device_icon: 'device_one',
          id: 0
        },
        {
          name: '灌装机',
          isactive: false,
          com: 'two',
          device_icon: 'device_two',
          id: 1
        },
        {
          name: '铆合拉杆装配线',
          isactive: false,
          com: 'three',
          device_icon: 'device_three',
          id: 2
        },
        {
          name: '上料桁架',
          isactive: false,
          com: 'four',
          device_icon: 'device_four',
          id: 3
        },
        {
          name: '堆焊机',
          isactive: false,
          com: 'five',
          device_icon: 'device_five',
          id: 4
        }
      ],
      timer: null,
      sideshow: 0,
      name: 'one'
    }
  },
  components: {
    one,
    two,
    three,
    four,
    five
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const _this = this
      this.timer = setInterval(() => {
        _this.sideshow++
        if (_this.sideshow === 5) {
          _this.sideshow = 0
          _this.name = _this.device[0].com
        }
        const num = _this.sideshow
        _this.device.forEach(item => {
          item.isactive = false
        })
        _this.name = _this.device[num].com
        _this.device[num].isactive = true
      }, 5000)
    }
  }
}
</script>

<style>

.device_list{
  display: flex;
  width: 100%;
  height: 142px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

</style>
