<template>
  <div class="pie">
  </div>
</template>

<script>
import { getDevice } from '@/api/device_port.js'
import * as echarts from 'echarts'
export default {
  name: 'pie',
  data () {
    return {
      // 待机
      Standby: 0,
      // 异常
      abnormal: 0,
      // 关机
      shutdown: 0,
      // 加工
      processing: 0
    }
  },
  mounted  () {
    this.init()
    setInterval(() => {
      this.init()
    }, 15000)
  },
  methods: {
    async init () {
      const { data: tret } = await getDevice()
      if (tret.code === 200) {
        this.shutdown = tret.data.stop
        this.Standby = tret.data.free
        this.abnormal = tret.data.abnormal
        this.processing = tret.data.running
      }
      // var date = new Date()
      // var day = this.dateFormat('dd', date)
      // var time = parseInt(this.dateFormat('HH', date))
      // var _this = this
      // var all = parseInt(localStorage.getItem('activeDevice')) * 0.92
      // if (day === '22' || day === '29') {
      //   // 周末上午与下午
      //   if ((time >= 9 && time <= 11) || (time >= 14 && time <= 17)) {
      //     setTimeout(() => {
      //       _this.processing = parseInt(all * 0.3)
      //       _this.Standby = parseInt(all * 0.1)
      //       _this.shutdown = parseInt(all * 0.57)
      //       _this.abnormal = parseInt(all * 0.03)
      //       localStorage.setItem('devicestate', [_this.processing, _this.Standby, _this.shutdown, _this.abnormal])
      //       _this.init()
      //     }, 3000)
      //     // 周末中午
      //   } else if ((time >= 12 && time <= 14)) {
      //     setTimeout(() => {
      //       _this.processing = parseInt(all * 0.18)
      //       _this.Standby = parseInt(all * 0.10)
      //       _this.shutdown = parseInt(all * 0.7)
      //       _this.abnormal = parseInt(all * 0.02)
      //       localStorage.setItem('devicestate', [_this.processing, _this.Standby, _this.shutdown, _this.abnormal])
      //       _this.init()
      //     }, 3000)
      //   }
      // } else {
      //   // 工作日上午与下午
      //   if ((time >= 9 && time <= 11) || (time >= 14 && time <= 17)) {
      //     setTimeout(() => {
      //       _this.processing = parseInt(all * 0.7)
      //       _this.Standby = parseInt(all * 0.2)
      //       _this.shutdown = parseInt(all * 0.07)
      //       _this.abnormal = parseInt(all * 0.03)
      //       localStorage.setItem('devicestate', [_this.processing, _this.Standby, _this.shutdown, _this.abnormal])
      //       _this.init()
      //     }, 3000)
      //     // 工作日中午
      //   } else if ((time >= 12 && time <= 14)) {
      //     setTimeout(() => {
      //       _this.processing = parseInt(all * 0.37)
      //       _this.Standby = parseInt(all * 0.53)
      //       _this.shutdown = parseInt(all * 0.08)
      //       _this.abnormal = parseInt(all * 0.02)
      //       localStorage.setItem('devicestate', [_this.processing, _this.Standby, _this.shutdown, _this.abnormal])
      //       _this.init()
      //     }, 3000)
      //   }
      // }
      var chartDom = document.querySelector('.pie')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '10',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              {
                value: this.Standby,
                name: '待机状态',
                itemStyle: {
                  normal: {
                    color: '#f08e03'
                  }
                }
              },
              {
                value: this.abnormal,
                name: '异常状态',
                itemStyle: {
                  normal: {
                    color: '#fa2929'
                  }
                }
              },
              {
                value: this.shutdown,
                name: '关机状态',
                itemStyle: {
                  normal: {
                    color: '#828282'
                  }
                }
              },
              {
                value: this.processing,
                name: '加工状态',
                itemStyle: {
                  normal: {
                    color: '#5ebc38'
                  }
                }
              }
            ]
          }
        ]
      }
      myChart.clear()
      option && myChart.setOption(option, true)
    }
    // dateFormat (fmt, date) {
    //   let ret
    //   const opt = {
    //     'Y+': date.getFullYear().toString(), // 年
    //     'm+': (date.getMonth() + 1).toString(), // 月
    //     'd+': date.getDate().toString(), // 日
    //     'H+': date.getHours().toString(), // 时
    //     'M+': date.getMinutes().toString(), // 分
    //     'S+': date.getSeconds().toString() // 秒
    //     // 有其他格式化字符需求可以继续添加，必须转化成字符串
    //   }
    //   for (const k in opt) {
    //     ret = new RegExp('(' + k + ')').exec(fmt)
    //     if (ret) {
    //       fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
    //     };
    //   };
    //   return fmt
    // }
  }
}
</script>

<style>
  .pie{
    width: 100%;
    height: 100%;
  }
</style>
