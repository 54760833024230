<template>
  <div class="chartline">

  </div>
</template>

<script>
import { getmonthParams } from '@/api/device_port.js'
import * as echarts from 'echarts'
export default {
  data () {
    return {
      device_data: []
    }
  },
  mounted () {
    this.chartLine()
    setInterval(() => {
      this.chartLine()
    }, 15000)
  },

  methods: {
    async chartLine () {
      const { data: ret } = await getmonthParams()
      if (ret.code === 200) {
        this.device_data = []
        ret.data.monthCnt.forEach(item => {
          this.device_data.push(item.paramCnt)
        })
      }
      // setTimeout(() => {
      //   this.device_data = []
      //   var devicedata = parseInt(localStorage.getItem('device_data_endVal'))
      //   this.device_data.push(parseInt(devicedata * 0.04))
      //   this.device_data.push(parseInt(devicedata * 0.15))
      //   this.device_data.push(parseInt(devicedata * 0.30))
      //   this.device_data.push(parseInt(devicedata * 0.39))
      //   this.device_data.push(parseInt(devicedata * 0.65))
      //   this.device_data.push(parseInt(devicedata * 0.88))
      //   this.device_data.push(devicedata)
      //   this.chartLine()
      // }, 1000)
      var mychart = echarts.init(document.querySelector('.chartline'))
      const option = {
        color: ['#f08e03'],
        tooltip: {
          // 通过坐标轴来触发
          trigger: 'axis'
        },
        grid: {
          top: '20%',
          left: '2%',
          right: '5%',
          bottom: '5%',
          show: false,
          borderColor: '#012f4a',
          containLabel: true,
          borderWidth: 0
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月'],
          axisTick: {
            show: false
          },
          // 修饰刻度标签的颜色
          axisLabel: {
            color: '#959595'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255,251,246,.3)'
            }
          },
          axisLine: {
            // X轴颜色
            lineStyle: {
              color: '#959595'
            }
          },
          nameLocation: 'start',
          nameTextStyle: {
            color: '#959595'
          }
        },
        yAxis: {
          type: 'value',
          // 去除刻度
          axisTick: {
            show: true,
            lineStyle: {
              color: '#959595'
            }
          },
          // Y轴颜色
          axisLine: {
            lineStyle: {
              color: '#959595'
            }
          },
          splitLine: {
            show: false
          },
          // 修饰刻度标签的颜色
          axisLabel: {
            color: '#959595'
          }
          // 修改y轴分割线的颜色
          // nameTextStyle: {
          //   color: '#959595'
          // }
        },
        series: [
          {
            name: '数量',
            type: 'line',
            // 是否让线条圆滑显示
            smooth: true,
            data: this.device_data
          }
        ]
      }
      mychart.clear()
      option && mychart.setOption(option)
    }
  }
}
</script>

<style>
  .chartline{
    width: 100%;
    height: 100%;
  }
</style>
