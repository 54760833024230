<template>
  <div class="active">
    <countTo
      :startVal="startVal"
      :endVal="endVal"
      :duration="3000"
      style="font-family: MyFontName"
      class="number"
      suffix="台"
    ></countTo>
    <div class="fir_text">上云设备</div>
    <el-progress
      type="circle"
      :percentage="percentage"
      :width="100"
      color="#f1930b"
    ></el-progress>
    <div class="progress_text">上云已激活:<span>{{active}}</span></div>
  </div>
</template>

<script>
import { getDevice } from '@/api/device_port.js'
import countTo from 'vue-count-to'
export default {
  name: 'dataMoudle',
  data () {
    return {
      startVal: 0,
      endVal: 0,
      // 激活台数
      active: 0,
      percentage: 0
    }
  },
  components: {
    countTo
  },
  created () {
    this.init()
    setInterval(() => {
      this.init()
    }, 15000)
  },
  methods: {
    async init () {
      const { data: ret } = await getDevice()
      if (ret.code === 200) {
        this.startVal = this.endVal
        this.endVal = ret.data.deviceTotal
        this.active = ret.data.activeCnt
        var num = parseFloat(this.active / this.endVal * 100)
        this.percentage = parseInt(num.toFixed(2))
        localStorage.setItem('noactvepercentage', 100 - this.percentage)
      }
    }
    // init () {
    //   this.endVal = parseInt(localStorage.getItem('activeDevice')) || 5273
    //   this.active = parseInt(this.endVal * 0.92)
    //   var _this = this
    //   setTimeout(() => {
    //     _this.endVal++
    //     localStorage.setItem('activeDevice', _this.endVal)
    //     _this.active = parseInt(_this.endVal * 0.92)
    //     setInterval(() => {
    //       _this.startVal = _this.endVal
    //       _this.endVal++
    //       parseInt(this.endVal = localStorage.getItem('activeDevice') || 5273)
    //       _this.active = parseInt(_this.endVal * 0.92)
    //     }, 600000)
    //   }, 0)
    // }
  }
}
</script>
