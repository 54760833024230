<template>
  <div class="Interface-call">
    <div class="call-left">
      <span class="call-number">108</span>
      <div class="call-bg-img">
        <img src="../../assets/img/main/bling.png" class="bling" />
        <img src="../../assets/img/main/block2.png" class="block2" />
        <img src="../../assets/img/main/block1.png" class="block1" />
      </div>
      <span class="call-title">开放接口总数</span>
    </div>
    <div class="call-right">
      <div
        :style="{ height: '200px', width: '320px' }"
        ref="bar"
        class="call-bar"
      ></div>
      <div class="call-comp">
        <div>
          <span class="bar-title">总次数</span>
          <span class="bar-value bar-value1">{{ this.allCount }}</span>
        </div>
        <div>
          <span class="bar-title tit1">调用接口数</span>
          <span class="bar-value bar-value2 top1">{{ this.post }}</span>
        </div>
        <div>
          <span class="bar-title tit2">当日调用接口数</span>
          <span class="bar-value bar-value1 top2">{{ this.todayPost }}</span>
        </div>
        <div>
          <span class="bar-title tit3">当日调用总次数</span>
          <span class="bar-value bar-value2 top3">{{
            this.todayTotalPost
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getcallInterface } from '@/api/device_port.js'
import * as echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/grid'
export default {
  name: 'Organization',
  components: {

  },
  data () {
    return {
      allCount: 0,
      post: 0,
      todayPost: 0,
      todayTotalPost: 0
    }
  },
  mounted () {
    this.drawBar()
    setInterval(() => {
      this.drawBar()
    }, 15000)
  },
  methods: {

    // let percent = 0
    // const H = new Date().getHours()
    // const M = new Date().getMinutes()
    // if (H < 8) {
    //   percent = 0.05
    // }
    // if ((H >= 9 && H < 11) || (H >= 14 && H < 17)) {
    //   percent = 0.425
    // }
    // if (H >= 13 && H < 14) {
    //   percent = 0.1
    // }
    // if (H === 11 && M <= 30) {
    //   percent = 0.425
    // }
    // if (H === 11 && M > 30) {
    //   percent = 0.1
    // }
    // const allCount = (parseInt(Math.random() * 100) + 2700) * (108 * 0.6) * 50 * 30
    // const post = (parseInt(Math.random() * 100) + 2700) * (40 * 0.6) * 50 * 30
    // const todayPost = (parseInt(Math.random() * 100) + 2700) * (108 * 0.6) * 50 * percent
    // const todayTotalPost = (parseInt(Math.random() * 100) + 2700) * (40 * 0.6) * 50 * percent
    // this.allCount = allCount
    // this.post = post
    // this.todayPost = todayPost
    // this.todayTotalPost = todayTotalPost
    async drawBar () {
      const { data: ret } = await getcallInterface()
      if (ret.code === 200) {
        this.allCount = ret.data.total
        this.post = ret.data.callTotal
        this.todayPost = ret.data.todayCallTotal
        this.todayTotalPost = ret.data.todayTotal
      }
      const bar = echarts.init(this.$refs.bar)
      const option = {
        legend: {
          data: []
        },
        grid: {
          left: 0,
          right: 0,
          bottom: 0,
          top: '10px'
          //   containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          show: false
        },
        yAxis: {
          type: 'category',
          data: ['当日调用总次数', '当日调用接口数', '调用接口数', '总次数'],
          show: false
        },
        series: [
          {
            name: '',
            type: 'bar',
            data: [{
              value: this.todayTotalPost,
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(1,
                    0, 0, 1, [{
                      offset: 0,
                      color: 'rgba(240, 142, 3, 1)'
                    }, {
                      offset: 1,
                      color: 'rgba(253, 222, 104, 1)'
                    }])
                }
              }
            }, {
              value: this.todayPost,
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(1,
                    0, 0, 1, [{
                      offset: 0,
                      color: 'rgba(255, 235, 157, 1)'
                    }, {
                      offset: 1,
                      color: 'rgba(255, 251, 246, 1)'
                    }])
                }
              }
            }, {
              value: this.post,
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(1,
                    0, 0, 1, [{
                      offset: 0,
                      color: 'rgba(240, 142, 3, 1)'
                    }, {
                      offset: 1,
                      color: 'rgba(253, 222, 104, 1)'
                    }])
                }
              }
            }, {
              value: this.allCount,
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(1,
                    0, 0, 1, [{
                      offset: 0,
                      color: 'rgba(255, 235, 157, 1)'
                    }, {
                      offset: 1,
                      color: 'rgba(255, 251, 246, 1)'
                    }])
                }
              }
            }],
            label: {
              show: false,
              position: 'right'
            },
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(255, 251, 246, 0.16)'

            },
            barWidth: '12px',
            barCategoryGap: '30px'
          }
        ]
      }
      bar.clear()
      bar.setOption(option)
    }
  }
}
</script>

<style>
.Interface-call {
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: start; */
}
.Interface-call .call-left {
  width: 134px;
  text-align: center;
  margin-right: 22px;
  margin-top: 20px;
}
.Interface-call .call-left .call-number {
  font-size: 26px;
  font-family: YouSheBiaoTiHei;
  font-weight: 400;
  color: #fffbf6;
  line-height: 14px;
  margin-bottom: 26px;
  display: block;
}
.Interface-call .call-left .call-title {
  font-weight: 500;
  color: #ffbe61;
  line-height: 10px;
  font-size: 16px;
  margin-top: 14px;
  display: block;
}
.Interface-call .call-left .call-bg-img {
  text-align: center;
  width: 142px;
  height: 80px;
  position: relative;
  background: url(../../assets/img/main/bottombg.png) no-repeat center bottom;
}
.Interface-call .call-left .call-bg-img .bling {
  position: absolute;
  left: 4px;
  bottom: 20px;
  animation: blingbling 2s ease infinite;
}
.Interface-call .call-left .call-bg-img .block1 {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -29px;
  animation: topdonw 2s ease infinite;
}
.Interface-call .call-left .call-bg-img .block2 {
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left: -36px;
  animation: downtop 2s ease infinite;
}
.Interface-call .call-right {
  /* width: 320px; */
  position: relative;
}
.Interface-call .call-bar {
  width: 320px;
  position: absolute;
  z-index: 1;
  left: 0;
}
.Interface-call .call-right .call-comp {
  width: 320px;
  position: absolute;
  color: rgba(246, 246, 246, 1);
  font-size: 12px;
  margin-bottom: 13px;
  left: 0;
}
.Interface-call .call-right .call-comp .bar-title {
  display: block;
  /* margin-bottom: 2px; */
  position: absolute;
  top: 10px;
}
.Interface-call .call-right .call-comp .tit1 {
  top: 55px;
}
.Interface-call .call-right .call-comp .tit2 {
  top: 105px;
}
.Interface-call .call-right .call-comp .tit3 {
  top: 150px;
}
.Interface-call .call-right .call-comp .bar-value {
  position: absolute;
  left: 325px;
  top: 26px;
}
.Interface-call .call-right .call-comp .bar-value1 {
  color: rgba(250, 232, 206, 1);
}
.Interface-call .call-right .call-comp .top1 {
  top: 73px;
}
.Interface-call .call-right .call-comp .top2 {
  top: 121px;
}
.Interface-call .call-right .call-comp .top3 {
  top: 168px;
}
.Interface-call .call-right .call-comp .bar-value2 {
  color: rgba(240, 142, 3, 1);
}
.Interface-call .call-right .call-comp .bar1 {
  width: 320px;
  height: 12px;
  background: rgba(255, 251, 246, 0.16);
}
.Interface-call .call-right .call-comp .bar1 .bar {
  transition: all 1s;
}
@keyframes blingbling {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes topdonw {
  0% {
    top: 0px;
  }

  /* 25% {
      top: 5px;
    } */

  50% {
    top: 5px;
  }
  /*
    75% {
      top: 5px;
    } */

  100% {
    top: 0px;
  }
}
@keyframes downtop {
  0% {
    top: 20px;
  }
  /*
    25% {
      top: 20px;
    } */

  50% {
    top: 10px;
  }

  100% {
    top: 20px;
  }
}
</style>
